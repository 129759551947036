.notification-center {
  position: relative;
  margin-bottom: 1em;
}

.notification-center-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.notification-center-updated {
  font-size: 0.9rem;
  font-weight: normal;
}

.notification-center-float {
  position: absolute;
  top: 0;
  right: 0;
}
