/* reportViewer.css */
body { zoom: 80% }

.react-tabs__tab--selected {
  font-weight: bold;
}

.report-viewer-container {
  margin-bottom: 50px; /* Adjust the value as needed for the desired margin */
}

.report-header { margin-bottom: 30px; }

#experience-container .quicksight-embedding-iframe {
  display: block;
  margin: 0 auto;
}
.executive-summary {
  margin: 0 20px;
}
.report-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.download-button {
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 22px 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
