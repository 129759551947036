.notification-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.notification-icon {
  margin-right: 1rem;
  width: 2em !important; /* Set a fixed width for the icons and ensure it's not overridden */
  text-align: center;
}

.view-update-link {
  font-weight: bold;
  text-decoration: underline;
  color: black;
  margin-left: 0.5rem;
}
