.exec-summary h1, .exec-summary h2, .exec-summary h3, .exec-summary h4, .exec-summary h5, .exec-summary h6 {
  margin-top: .80em;
  margin-bottom: 0.5em;
}

.exec-summary p {
  margin-bottom: 1em;
}

.exec-summary ul, .exec-summary ol {
  margin-bottom: 1em;
}

.exec-summary li {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.exec-summary blockquote {
  margin-left: 1em;
  margin-right: 1em;
  padding: 0.5em;
  border-left: 3px solid #ccc;
  background-color: #f9f9f9;
}
.appendix img {
  max-width: 80%;
  height: auto;
}
