.product-card {
  background-color: #FAFAFA;
  border-radius: 17px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: relative;
  min-width: 310px;
}

.product-card-header h3 {
  font-size: 1.5rem;
  margin-bottom: 0.7em;
  font-weight: 400;
  margin-left: 0.4em;
  letter-spacing: 0.05em;
}

.plus-icon {
  font-size: 2.25rem;
  margin-right: 0.5rem;
  margin-bottom: 1.0rem;
  color: #6D42E1;
}

.product-card-links {
  list-style: none;
  padding: 0;
}

.product-card-links li {
  margin-bottom: 0.7em;
  font-size: 1.1em;
}

.product-card-links li a {
  text-decoration: underline;
  color: inherit;
}

.product-card-header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.check-icon {
  margin-right: 0.5rem;
  width: 1.5em !important;
  color: #90CB75;
}
.ghost-plus-icon {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 0.5rem;
  margin-bottom: 1.0rem;
  background-color: #e0e0e0;
}

.ghost-title {
  background-color: #e0e0e0;
  width: 70%;
  height: 1.5rem;
}

.ghost-link {
  background-color: #e0e0e0;
  width: 100%;
  height: 1.1em;
  margin-bottom: 0.7em;
}
