.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

input[type=file] {
  width: 350px;
  width: 100%;
  margin:auto auto;
  height:380px;
  max-width: 100%;
  color: #444;
  padding: 5px;
  background: #fff;
  border-radius: 10px;
  border: 2px dashed #555;
}

.progressbox, .uploadbox {
  width: 80%;
  margin: 0 auto;
}
.status {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.upload-complete {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #555;
  color: #444;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

/*
.drop-container:hover {
  background: #eee;
  border-color: #111;
}
*/

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

#qsHomeLoginBtn {display:block;margin:0 auto;}
body {
  background-color: #EFEFEF;
}
.navbar {
  background-color: inherit !important;
  box-shadow: none !important;
}

.custom-footer-bg {
  background-color: inherit;
  box-shadow: none !important;
}
